<template>
  <div class="partner-dropdown-list scrollbar">
    <template v-for="(societyPartner, indexP) in formattedPartnerToList" :key="`partner_${indexP}`">
      <div
        class="partner-list-item-container"
        :class="{ 'darker-background-color': idSelectedSocietyPartner == societyPartner.id }"
        @click="$emit('select', societyPartner.id!)"
      >
        <div class="partner-name">
          <CommonsTextTooltipIfTextOverflowed :text="societyPartner.label" />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import Fuse from "fuse.js";

const partnerStore = usePartnerStore();

defineEmits<{
  (e: "select", id: number): void;
}>();

const props = defineProps({
  idSelectedSocietyPartner: {
    type: Number,
    default: null,
  },
  search: {
    type: String,
    required: false,
    default: null,
  },
});

const societyPartnersOptions = computed(() => {
  const societyPartners = partnerStore.partners.filter((p) => !isDefined(p.deletedAt));
  return societyPartners.map((partner) => ({
    ...partner,
    label: partner.company ? `${partner.company}` : `${partner.firstname} ${partner.lastname}`,
  }));
});

const formattedPartnerToList = computed(() => {
  if (props.search === "" || props.search === null) {
    return societyPartnersOptions.value;
  }

  const fuseOptions = {
    shouldSort: true,
    threshold: 0.4,
    location: 0,
    distance: 100,
    maxPatternLength: 40,
    minMatchCharLength: 1,
    keys: ["label"],
  };

  const fuseInstanceComparingToLabel = new Fuse(societyPartnersOptions.value, fuseOptions);
  return fuseInstanceComparingToLabel.search(props.search ?? "").map((fsItem) => {
    return fsItem.item;
  });
});
</script>

<style lang="scss" scoped>
.partner-dropdown-list {
  height: 200px;
}

.partner-list-item-container {
  // height: auto;
  // min-height: 40px;
  width: 100%;
  padding: $uds-spacing-1 0 $uds-spacing-1 20px;
  box-sizing: border-box;

  display: flex;
  align-items: center;

  &:hover {
    background-color: $uds-neutral-100;
    cursor: pointer;
  }

  &.darker-background-color {
    background-color: $uds-neutral-100;
  }

  .partner-name {
    flex-grow: 1;
    width: 10px;
    font-size: 16px;
    line-height: 20px;
    color: $uds-neutral-900;
  }
}
</style>
