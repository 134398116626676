<template>
  <div class="partner-dropdown-container">
    <div class="partner-dropdown-search">
      <CommonsDropdownsDropdownSearch v-model:data="search" />
    </div>
    <div class="partner-dropdown-list scrollbar">
      <CommonsDropdownsPartnerDropdownItemLine
        :id-selected-society-partner="idSelectedSocietyPartner"
        :search="search ?? undefined"
        @select="$emit('select', $event)"
      />
    </div>
    <slot name="creationmodal" />
  </div>
</template>

<script setup lang="ts">
defineProps({
  idSelectedSocietyPartner: {
    type: Number,
    default: null,
  },
});

defineEmits<{
  (e: "select", id: number): void;
  (e: "close"): void;
}>();

const search = ref<string | null>(null);
</script>

<style lang="scss" scoped>
.partner-dropdown-container {
  width: 100%;
  box-shadow: 0px 6px 16px rgba(32, 33, 36, 0.12);
  border-radius: $uds-radius-1;
  background: $uds-white;

  // on every direct child
  & > * {
    border-bottom: 1px solid $uds-neutral-500;

    &:last-child {
      border-bottom: 0px;
    }
  }

  .partner-dropdown-search {
    height: 40px;

    display: flex;
    align-items: center;
  }

  .partner-dropdown-list {
    height: 160px;
    padding: $uds-spacing-0_5 0;
  }
}
</style>
